import { PageProps } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React, { Suspense } from 'react';

import Layout from '../../../../components/Layout';
import { WLContainer } from '../../../../design-library';
import * as Styles from './index.module.scss';
const MasterForm = React.lazy(
  () =>
    import(
      '../../../../components/LifeInsuranceCalculatorV2/components/MasterForm/MasterForm'
    ),
);

interface LifeCalculatorProps extends PageProps<undefined> {}

const LifeCalcPage: React.FC<LifeCalculatorProps> = ({ location }) => {
  const mixpanel = useMixpanel();
  const [params, setParams] = React.useState<{ [key: string]: string }>();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();

    let newParams: { [key: string]: string } = {};
    for (const entry of entries) {
      if (entry[0].substring(0, 4).toLowerCase() === 'utm_') {
        newParams[entry[0]] = entry[1];
      }
    }
    setParams(newParams);
    const ts = Math.floor(Date.now() / 1000);

    let Referral_Source = urlParams.get('utm_source') || '';
    let Referral_Medium = urlParams.get('utm_medium') || '';
    let Referral_Campaign = urlParams.get('utm_campaign') || '';
    let Referral_Content = urlParams.get('utm_content') || '';

    mixpanel.track('Life Insurance Calculator - Start', {
      'Referral Source': Referral_Source,
      'Referral Medium': Referral_Medium,
      'Referral Campaign': Referral_Campaign,
      'Referral Content': Referral_Content,
      Viewed: true,
      'Start Time': ts,
    });
  }, []);

  return (
    <Layout
      seo={{
        title: 'Life Insurance Calculator',
        description:
          'Find out how much life insurance you need with our life insurance calculator. Receive a customized estimate based on your unique situation.',
      }}
      currentPath={location.pathname}
    >
      <WLContainer className={Styles.Container} width="WIDE">
        <Suspense fallback={<div>Loading...</div>}>
          <MasterForm utmParameters={params ? JSON.stringify(params) : null} />
        </Suspense>
      </WLContainer>
    </Layout>
  );
};

export default LifeCalcPage;
